









































































































































import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BPagination,
} from "bootstrap-vue";
import { Ref, ref } from "@vue/composition-api";
import { AvButton, AvExportButton } from "@/components";
import { AvSkeletonTable } from "@/components/av-skeleton";
import CountiesHandler from "../city-handler/CityHandler.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ENUMS from "@/enums";
import vSelect from "vue-select";

// Services
import { useState, useCity, useArea } from "@/services";

// Interfaces
import { BvTableFieldArray } from "bootstrap-vue";
import {
  ICity,
  IResponseListCounties,
} from "@core/services/interfaces/covarege-area/city/ICityService";
import {
  IAreaStore,
  ICounties,
  IStateStore,
} from "@/store/admin-panel/city/cityState";
import {
  IResponseListState,
  IStateApi,
} from "@core/services/interfaces/covarege-area/state/IStateService";
import {
  IArea,
  IResponseListArea,
} from "@core/services/interfaces/covarege-area/area/IAreaService";

@Component({
  name: "CountiesList",
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    AvButton,
    AvExportButton,
    CountiesHandler,
    AvSkeletonTable,
    vSelect,
  },
})
export default class CountiesList extends Vue {
  // Data
  $ENUMS = ENUMS;
  loading = false;
  isCityHandlerSidebarActive: Ref<boolean> = ref(false);
  statesOptions: Array<{ label: string; value: string }> = [];
  areasOptions: Array<{ label: string; value: string }> = [];
  searchQuery = "";
  sortBy = "name";
  perPage: number = 10;
  perPageOptions: Array<number> = [10, 25, 50, 100];
  isSortDirDesc = false;
  currentPage: number = 1;
  display: number = 0;
  total: number = 0;
  filterOn: ICity[] = [];
  cityId: number | null = null;
  $refs = {
    refListTable: {} as any,
  };

  statusOptions: Array<{ label: string; value: boolean }> = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ];

  tableColumns: BvTableFieldArray = [
    { key: "name", label: "Nome", sortable: true },
    { key: "codeSerpro", label: "SERPRO", sortable: true },
    { key: "codeIbge", label: "Cód. IBGE", sortable: true },
    { key: "state", label: "Estado", sortable: true },
    { key: "areaInfluenciaNome", label: "Área Op.", sortable: true },
    {
      key: "regiaoOperacionalNome",
      label: "Region Op.",
      sortable: true,
    },
    { key: "capital", label: "Capital", sortable: true },
    { key: "author", label: "Autor", sortable: true },
    { key: "actions", label: "Ações" },
  ];

  // Computed
  get getCounties(): Array<ICity> {
    return this.$store.state["admin-city"].counties;
  }

  get getStates(): Array<IStateStore> {
    return this.$store.state["admin-city"].states;
  }

  get getAreas(): Array<IStateStore> {
    return this.$store.state["admin-city"].areas;
  }

  get dataMeta() {
    const localItemsCount = this.getAreas ? this.getAreas.length : 0;

    return {
      from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
      to:
        this.perPage * this.currentPage < this.display
          ? this.perPage * this.currentPage
          : this.display,
      of: this.display,
      total: this.total,
    };
  }

  // Watchs
  @Watch("getStates", { immediate: true })
  setStatesOptions() {
    this.statesOptions = this.getStates.map((sts) => ({
      label: sts.name,
      value: sts.id,
    }));
  }

  @Watch("getAreas", { immediate: true })
  setAreasOptions() {
    this.areasOptions = this.getAreas.map((ar) => ({
      label: ar.name,
      value: ar.id,
    }));
  }

  // Lifecycle
  created() {
    this.refreshData();
    this.fetchListStates();
    this.fetchListArea();
  }

  // Methods
  onFiltered(filteredItems: Array<any>) {
    this.display = filteredItems.length;
    this.currentPage = 1;
  }

  fetchListStates() {
    useState
      .requestList({
        paginar: false,
        municipios: [],
      })
      .then((response: IResponseListState) => {
        const data: IStateStore[] = response.data.Data.data.map(
          (state: IStateApi) => ({
            id: state.Id,
            name: state.Nome,
            sigla: state.Sigla,
          })
        );

        this.$store.commit("admin-city/SET_STATE", data);
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar a lista de estados!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }

  fetchListArea() {
    useArea
      .requestList({ paginate: false, draw: 1, length: 10000 })
      .then((response: IResponseListArea) => {
        const data: Array<IAreaStore> = response.data.Data.data.map(
          (area: IArea) => ({
            id: area.Id || 0,
            name: area.Nome,
            code: area.Codigo,
          })
        );

        this.$store.commit("admin-city/SET_AREAS", data);
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar a lista de áreas!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }

  clearCityIdData = (): void => {
    this.cityId = null;
  };

  /**
   * Essa função é acionada quando queremos abrir o modal de editar grupo
   */
  handlerCitySidebar(cityId: number): void {
    this.isCityHandlerSidebarActive.value = true;
    this.cityId = cityId;
  }

  updateIsCityHandlerSidebarActive = (val: boolean): void => {
    this.isCityHandlerSidebarActive.value = val;
  };

  /**
   * Atualizar tabela
   */
  refreshData(): void {
    this.loading = true;
    useCity
      .requestList({
        paginar: false,
        draw: 1,
        length: 10,
      })
      .then((response: IResponseListCounties) => {
        const data: ICounties[] = response.data.Data.data.map((city) => ({
          name: city.Nome,
          codeSerpro: city.CodigoSerpro,
          codeIbge: parseInt(city.CodigoIbge) || 0,
          areaInfluenciaNome: city.AreaInfluenciaNome,
          regiaoOperacionalNome: city.RegiaoOperacionalNome,
          capital: city.Capital,
          author: city.Author || "",
          id: city.Id,
          state: city.EstadoNome,
        }));

        this.$store.commit("admin-city/SET_COUNTY", data);
        this.display = data.length;
        this.total = data.length;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar a lista de municípios!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  getCities() {
    return new Promise((resolve) => {
      const data = this.$refs.refListTable.filteredItems.map(
        (city: ICounties) => ({
          Nome: city.name,
          "Cód. SERPRO": city.codeSerpro,
          "Cód. IBGE": city.codeIbge,
          Capital: city.capital ? "Sim" : "Não",
          Estado: city.state,
          "Área Influência": city.areaInfluenciaNome,
          "Região Operacional": city.regiaoOperacionalNome,
          Autor: city.author || "",
        })
      );

      resolve(data);
    });
  }
}
